














































































































import { Component, Mixins } from 'vue-property-decorator';
import DialogMixin from '@/mixins/dialog';
import { savedSearchModule } from '@/store/modules/savedSearch';
import { projectModule } from '@/store/modules/project';

@Component
export default class DialogSaveSearch extends Mixins(DialogMixin) {
    private nameSearch = '';
    sendToAnother = false;

    get filter() {
        return projectModule.filter;
    }

    get blockedFirstStep() {
        return this.sendToAnother || !this.isASavedSearch;
    }

    async store() {
        await savedSearchModule.savedSearch({
            clienteId: this.selectedCustomer._id,
            clientePrincipal: this.selectedCustomer.type === 'principal',
            nombre: this.nameSearch,
            filter: this.filter,
            url: this.nameSearch.replace(/ /g, '-')
        });
        this.active++;
    }

    async edit() {
        await savedSearchModule.editSearch(this.filter);
        this.close();
        this.$notify.success({
            title: 'Guardado!',
            message: 'Hemos actualizado la búsqueda con éxito.'
        });
    }

    close() {
        savedSearchModule.closeOrOpenDialog();
    }
}
